<template>
    <LayoutReconciliationTab 
        pageType="kits"
        :isDescription="true"
    />
</template>

<script>
import LayoutReconciliationTab from '../layouts/LayoutReconciliationTab.vue';
export default {
    name: 'PageReconciliationKits',
    components: {
        LayoutReconciliationTab
    }
}
</script>